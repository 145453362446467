<template>
  <div>
	<v-card class="pa-3 card flat-card" color="surface">
			<v-row>
				<v-col>
					<span class="base-font primaryText--text font-weight-bold">تماس باما</span>
				</v-col>
			</v-row>
			<v-row class="px-3" v-html="contact.full_text">
			</v-row>
		</v-card>
			<div id="map"></div>
		
	</div>
</template>
<style>
#map{
  width: 100%;
  height: 400px;
}
</style>
<script>
import { mapState } from 'vuex'
import { get_content } from '@/models/content'
import mapboxgl from 'mapbox-gl'
import MapboxLanguage from '@mapbox/mapbox-gl-language'
export default {
	computed: {
		...mapState({
		contact: state =>state.content
		})
	},
	mounted () {
	const config = JSON.parse(localStorage.getItem('config')).other_configs
	get_content(JSON.parse(config).contact_us_content_id)
	const lng = JSON.parse(config).lon
	const lat = JSON.parse(config).lat
	mapboxgl.accessToken = 'pk.eyJ1IjoiamF2aWQ5OSIsImEiOiJja20xbjlpNDQyNjI2Mm9wbG9nYXExczU3In0.L9_f-w-pvHBz6hUasFJMEA';
      const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/traffic-day-v2',
      center: [lng, lat],
      zoom: 15
      });
       const marker = new mapboxgl.Marker({
      draggable: false,
      }).setLngLat(map.getCenter())
      .addTo(map);
      mapboxgl.setRTLTextPlugin('https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js');
      const language = new MapboxLanguage({
      
	language: 'ar'
      })
map.addControl(language);
}
}
</script>